* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: unset;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  color: #363d4f;
}

body {
  background: var(--theme-ui-colors-background-default);
  font-family: "Roboto", "sans-serif", "Lato", "Segoe UI", -apple-system,
    BlinkMacSystemFont, "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

label {
  all: unset;
}
